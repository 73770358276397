import { Box, styledComponent, Container } from '@hermes/web-components'

export const ErrorBlockContainer = styledComponent(Box)(({ theme }) => ({
  backgroundColor: theme.palette.secondary[100],
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: '4px',
  lineHeight: '24px',
  marginBottom: '36px',
  padding: '16px'
}))

export const ImageBlock = styledComponent(Box)(({ theme }) => ({
  flex: 1,
  backgroundImage: 'url(/img/error-bg.jpg)',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '100% 0',
  borderLeft: `1px solid ${theme.palette.divider}`,
  [theme.breakpoints.down('md')]: {
    display: 'none'
  }
}))

export const ErrorPageContainer = styledComponent(Container)(({ theme }) => ({
  display: 'flex',
  height: 'calc(100vh - 93px)',
  [theme.breakpoints.down('md')]: {
    backgroundImage: 'url(/img/error-bg.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain'
  }
}))
