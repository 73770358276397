'use client'

import { Providers } from '@components/Providers'
import { Locale } from '@lib/utils/localeHelpers'
import { ErrorContainer } from 'containers/Error/Error'
import React from 'react'
import { Header } from './Header'

interface NotFoundProps {
  locale: Locale
  message: string
  translations: Record<string, string>
}

export const NotFound: React.FC<NotFoundProps> = ({ locale, message, translations }) => (
  <>
    <Providers translations={translations} locale={locale}>
      <Header localeOverride={locale} />
      <ErrorContainer statusCode={404} message={message} locale={locale} />
    </Providers>
  </>
)
