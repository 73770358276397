'use client'

import { createContext, useState, useEffect, ReactNode, useContext } from 'react'
import { useRouter } from 'next/navigation'
import { PagesNames } from '@hermes/types'
import { usePathname } from 'next/navigation'

type PrevRouteContextType = string

const PrevRouteContext = createContext<PrevRouteContextType>('N/A')

type PrevRouteProviderProps = {
  children: ReactNode
}

export const PrevRouteProvider = ({ children }: PrevRouteProviderProps) => {
  const router = useRouter()
  const path = usePathname() || ''
  const [prevRoute, setPrevRoute] = useState<string | null>(null)

  useEffect(() => {
    const handleRouteChange = () => {
      switch (true) {
        case path.includes('/more-from-seller'): {
          setPrevRoute(PagesNames.MORE_FROM_SELLER)
          break
        }
        case path.includes('/sellers'): {
          setPrevRoute(PagesNames.PICK_SELLER)
          break
        }
        case path.includes('/books'): {
          setPrevRoute(PagesNames.BOOK_DETAILS)
          break
        }
        default: {
          setPrevRoute('N/A')
          break
        }
      }
    }

    handleRouteChange()
  }, [path])

  return <PrevRouteContext.Provider value={prevRoute || 'N/A'}>{children}</PrevRouteContext.Provider>
}

export const usePreviousRouter = () => useContext(PrevRouteContext)
